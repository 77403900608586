import dynamic from "next/dynamic";
import Mapping from "../../dynamic/mapping";
import { SkeletonLoader } from "../SkeletonLoader/SkeletonLoader";

const Prelem = ({ content, analytics, authoringHelper, secondaryArgs, prelemId }) => {
  const PrelemComponent: any = dynamic(
    () => import("@platformx/prelems").then((mod) => mod?.[Mapping?.[prelemId]]),
    {
      ssr: false,
      loading: () => <SkeletonLoader />,
    },
  );

  return (
    <>
      {PrelemComponent ? (
        <PrelemComponent
          content={content}
          analytics={analytics}
          authoringHelper={authoringHelper}
          secondaryArgs={secondaryArgs}
        />
      ) : null}
    </>
  );
};

export default Prelem;

import { Box } from "@mui/material";
import * as Sentry from "@sentry/react";
import { GetServerSidePropsContext } from "next";
import { createRef, memo, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import ErrorBoundary from "../../components/Common/ErrorBoundary";
import { usePageImpression } from "../../components/Common/customHook/PageImpressionHook";
import HeaderFooterLayout from "../../components/HeaderFooterLayout/HeaderFooterLayout";
import PrelemComponent from "../../components/Prelem/PrelemComponent";
import PageHead from "../../components/pageHead";
import { BEHIND_LOGIN, CONTENT_TYPES, SNOWPLOW } from "../../constants/CommonConstants";
import useLoginDetailsSetter from "../../hooks/useLoginDetailsSetter";
import {
  captureLogs,
  navigateToHome,
  prelemBaseEndpointObj,
  sendVisitorData,
} from "../../utils/helperFunctions";
import { getContentData, getHeaderFooterData } from "../../utils/helperInitialData";
import { verifyAndLogin } from "../../utils/helperLogin";

interface Children {
  PrelemTag: Array<string>;
  PrelemId: string;
  PrelemName: string;
  SeoEnabled: boolean;
  AnalyticsEnabled: boolean;
  DocumentPath: string;
  DocumentType: string;
  IsHidden: boolean;
  StructuredData: Object;
}

interface Props {
  pageProps: any;
  pageDatas: any;
  pageData: any;
  instances: any;
  type: string;
  authState: any;
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const { query = {}, locale = "", req, res, resolvedUrl } = context;
  const { id = [] } = query;
  const host = req.headers.host || "";
  res.setHeader("site_host", host);
  res.setHeader("Cache-Control", "public, max-age=604800, stale-while-revalidate=604800");
  try {
    const [menuData, footerSettingData] = await getHeaderFooterData(locale || "en", host);
    const pageName =
      id?.length > 0 ? id[0] : menuData?.find((item: any) => item?.HomePage === true)?.URL || "";
    const contentResponse = await getContentData(
      CONTENT_TYPES.PAGE,
      pageName,
      locale || "en",
      host,
    );

    //Logic for login
    const isPageBehindLogin = contentResponse?.PageSettings?.PageViewer === BEHIND_LOGIN;
    const { userData = null, destination = "" } = await verifyAndLogin(
      req,
      res,
      host,
      query,
      pageName,
      resolvedUrl,
      locale,
      isPageBehindLogin,
    );
    if (userData && destination) {
      return {
        redirect: {
          permanent: false,
          destination,
        },
      };
    }

    // Logic to navigate to home when page doesn't exist
    if (!contentResponse) {
      const response = { contentResponse, footerSettingData };
      return navigateToHome(locale, response, "PAGE FAILED TO LOAD", { locale, host, id });
    }

    //redirect once cookie is set.
    if (!req.headers.cookie && isPageBehindLogin) {
      const response = { menuData, contentResponse, footerSettingData };
      return navigateToHome(locale, response, "PAGE FAILED TO LOAD", { locale, host, pageName });
    }
    return {
      props: {
        type: "",
        MenuData: menuData || [],
        footerSettingData: footerSettingData || {},
        route: { ...query, locale: locale, query: pageName, id: pageName, host, resolvedUrl },
        pageData: contentResponse || {},
        site_host: host,
        userData,
      },
    };
  } catch (error) {
    captureLogs(error, "error in getting page data");
    return {
      props: {
        type: "",
        MenuData: [],
        footerSettingData: {},
        route: {},
        pageData: {},
        site_host: host,
      },
    };
  }
}

const Home = (props: Props) => {
  const { pageProps = {}, authState = {}, instances = {} } = props;
  const {
    pageData = {},
    route = {},
    MenuData = [],
    footerSettingData = {},
    userData = {},
    site_host,
  } = pageProps;

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const myRefs = useRef([]);
  myRefs.current = pageData?.Children?.map(
    (_arrayTuple: Children, i) => myRefs.current[i] ?? createRef(),
  );

  const prelemBaseEndpoint = {
    ...prelemBaseEndpointObj(site_host),
    language: route?.locale,
    query: route?.query,
  };

  const handleClick = () => {
    // throw new Error("Sentry Test Error 12345");
    Sentry.captureException(new Error("App module failed"));
    throw new Error("App module failed 1");
  };

  useEffect(() => {
    sendVisitorData(pageData); // send visitor data to RPI
  }, [inView, Object.keys(instances)?.length]);

  useLoginDetailsSetter(userData);
  usePageImpression(pageData, inView, instances, SNOWPLOW.CONTENT_TYPE.PAGE, route, site_host);

  return (
    <Box ref={ref}>
      <ErrorBoundary>
        <PageHead
          pageData={{
            ...pageData,
            settings: {
              seo_title: pageData?.SeoEnable,
              socialog_url: `${prelemBaseEndpoint?.PublishEndPoint}${prelemBaseEndpoint?.language}/${route.id}`,
              socialog_title: pageData?.Title,
              seo_keywords: pageData?.PageSettings?.PageTags?.join(", "),
              socialog_image: "",
              SocialOgLocale: route?.locale,
              SocialOgSiteName: pageData?.SiteName,
              socialog_twitter_url: `${prelemBaseEndpoint?.PublishEndPoint}${prelemBaseEndpoint?.language}/${route.id}`,
              socialog_description: pageData?.PageSettings?.PageDescription,
              socialog_twitter_title: pageData?.Title,
              socialog_twitter_description: pageData?.PageSettings?.PageDescription,
            },
          }}
          favIcon={footerSettingData?.fav_icon}
        />
        <button
          style={{ position: "fixed", display: "none", top: 0, zIndex: 9999, left: "100px" }}
          type='button'
          onClick={handleClick}>
          click
        </button>
        <HeaderFooterLayout
          {...props}
          route={route}
          MenuData={MenuData}
          authState={authState}
          isCartIconEnable={true}
          footerSettingData={footerSettingData}
          prelemBaseEndpoint={prelemBaseEndpoint}>
          <PrelemComponent
            myRefs={myRefs}
            pageData={pageData}
            instances={instances}
            prelemBaseEndpoint={prelemBaseEndpoint}
            site_host={site_host}
          />
        </HeaderFooterLayout>
      </ErrorBoundary>
    </Box>
  );
};

export default memo(Home);
